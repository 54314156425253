<template>
  <b-row>
    <b-col cols="12" sm="6">     
        <b-card title="Dados do Hit">
          <label for="hitComposicao">Composição:</label>
          <b-form-input id="hitComposicao" v-model="hitInfo.nomeComposicao" readonly></b-form-input>
          <label for="hitCompositores">Compositores:</label>
          <b-form-input id="hitCompositores" v-model="hitInfo.nomeCompositores" readonly></b-form-input>
          <label for="hitTempoVenda">Tempo de Venda:</label>
          <b-form-input id="hitTempoVenda" :value="tempoVendaEnum(hitInfo.tempoVenda)" readonly></b-form-input>
          <label for="hitValorVenda">Valor de Venda:</label>
          <b-form-input id="hitValorVenda" :value="formatarMoeda(hitInfo.valorVenda)" readonly></b-form-input>
          <label for="hitValidade">Validade do documento:</label>
          <b-form-input id="hitValidade" v-model="hitInfo.validade" readonly></b-form-input>

          <b-row class="m-1 mt-2" style="border-top: 1px solid #CCC; padding-top: 20px;">
            <b-col cols="6" sm="10">
              
              <audio-player
                ref="audioPlayer"
                :audio-list="[hitInfo.arquivoMP3]"
                :show-playback-rate="false"
                :show-volume-button="false"
                :show-next-button="false"
                :show-prev-button="false"
                class="w-100 custom-audio-player"
              />
            </b-col>
        
            <b-col cols="4" sm="1">
              <b-button variant="primary" @click="downloadHit()" class="custom-button">
                <feather-icon v-if="!carregandoDownload" icon="DownloadIcon" size="18"/>
                <span v-if="carregandoDownload" class="ml-1 position-absolute" style="right: 20%; top: 0%; transform: translate(-20%, -0%);">
                  <vue-element-loading spinner="bar-fade-scale" color="#FFF" :active="carregandoDownload" class="custom-spinner"
                  />
                </span>
              </b-button>
            </b-col>
            <b-col cols="2" sm="1">
              <b-button variant="dark" @click="togglePDF()" class="custom-button" style="margin-left: 1px;">
                <feather-icon icon="FileTextIcon" size="18"/>
              </b-button>
            </b-col>
        </b-row>
        <b-row class="custom-buttons-row d-flex justify-content-between mt-2">
          <b-button
            class="btn_historico"
            variant="dark"
            size="sm"
            style="cursor: pointer"
            @click="visualizarHistorico()"
            >
            Visualizar Histórico
          </b-button>

          <b-button
            class="btn_exclusividade"
            variant="primary"
            size="sm"
            style="cursor: pointer"
            @click="solicitarExclusividade()"
            v-if="hitInfo.tipoNegociacao == '0'"
            >
            Solicitar Exclusividade
          </b-button>
        </b-row>
        </b-card>
    </b-col>
      
    <b-col cols="12" sm="6">
        <b-card title="Letra">
          <div class="campoLetra">
            <b-form-textarea
              id="hitLetra"
              v-model="hitInfo.letra"
              placeholder="Letra da música"
              rows="17"
              readonly
            />
          </div>
        </b-card>
    </b-col>

    <b-modal id="pdf-modal" ref="pdfModal" size="lg" title="Visualizar PDF" hide-footer>
      <iframe :src="pdfSrc" style="width:100%; height:80vh;" frameborder="0"></iframe>
    </b-modal>
    

        <!-- MODAL DE EXCLUSIVIDADE -->

      <b-modal id="modal-exclusividade" ref="modalExclusividade" ok-only ok-title="Solicitar Exclusividade" centered
        no-close-on-backdrop size="sm" title="Exclusividade" @ok="negociarExclusividadeHit">
      
        <template v-slot:modal-footer>
          <b-button block variant="primary" :disabled="invalid || respostaApi" @click="negociarExclusividadeHit">
            Solicitar
            <b-spinner v-if="respostaApi" small label="Carregando"/>
          </b-button>
        </template>

        <b-row class="mt-2">
          <b-col class="d-flex justify-content-center">
            <!-- media -->
            <b-media
              no-body
            >
                <b-media-aside>
                  <b-avatar rounded variant="light-success" size="34">
                    <feather-icon icon="DollarSignIcon" size="18"/>
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h6 class="mb-0">
                    Exclusividade
                  </h6>
                  <small>{{ hitInfo.valorExclusividade | currency }}</small>
                </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <validation-observer #default="{invalid}" ref="negociacaoForm">
          <b-form ref="negociacaoForms" class="mt-2" @submit.prevent="negociarHitCadastrar">
            <b-row>

              <b-col>
                <b-form-group label="Valor" label-for="hitValorPropostaLabel">
                  <validation-provider #default="{ errors }" name="hitValorPropostaLabel">
                    <money v-model="novaPropostaExclusividade" v-bind="money" class="form-control lg"
                      :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row class="mt-2">
              <b-col>
                <b-form-group label="Tempo de venda" label-for="hitTempoVenda">
                  <validation-provider #default="{ errors }" name="hitTempoVenda">
                    <v-select v-model="tempoVendaSelecionada" label="title" :options="tempoVenda"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-modal>
        
      <b-modal id="modal-historico" ref="modalHistorico" hide-footer
        centered no-close-on-backdrop size="lg" title="Histórico de Compra">
        <b-table :items="hitHistorico" responsive :fields="tabelaHistorico" striped hover show-empty empty-text="Sem resultados">
          <template #cell(dataInclusao)="data">
            <b>{{ data.item.dataInclusao }}</b>
          </template>
          <template #cell(valorVenda)="data">
            <b>{{ formatarMoeda(data.item.valorVenda) }}</b>
          </template>
          <template #cell(tipoNegociacao)="data">
            <b>{{ tipoNegociacaoEnum(data.item.tipoNegociacao) }}</b>
          </template>
          <template #cell(tempoVenda)="data">
            <b>{{ tempoVendaEnum(data.item.tempoVenda) }}</b>
          </template>
          <template #cell(arquivoPDF)="data">
            <b-button variant="dark" @click="abrirPDFModal(data.item.arquivoPDF)" class="custom-button">
              <feather-icon icon="FileTextIcon" size="18"/>
            </b-button>
          </template>
        </b-table>
      </b-modal>

  </b-row> 



  
</template>

<script>

import Vue from 'vue'


import {
 BAlert, BModal, BSpinner, BRow, BCol, BCard, BCardTitle, BCardHeader, BFormInvalidFeedback, BCardBody,
  BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody,
  BFormTextarea, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, RippleDirective, BTable
} from 'bootstrap-vue'
import router from '@/router'
import AudioPlayer from '@liripeng/vue-audio-player'
import VueAudio from 'vue-audio'

import useJwt from '@/auth/jwt/useJwt'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { Money } from 'v-money'
import vSelect from 'vue-select'
// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

export default {
  components: {
    BAlert,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormTextarea,
    BForm,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    AudioPlayer, 
    VueAudio,
    VueElementLoading,
    BSpinner,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    Money,
  },
  data() {
    return {
      hitId: null,
      hitInfo: [],
      hitHistorico: [],
      carregamentoApi: false,
      carregandoDownload: false,
      novaPropostaExclusividade: 0,
      pdfSrc: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
      tempoVendaSelecionada: {id: 0, title: '12 meses' },
      tempoVenda: [
        { id: 0, title: '12 meses' },
        { id: 1, title: '18 meses' },
        { id: 2, title: '24 meses' },
      ],
      tabelaHistorico: [
        { key: 'dataInclusao', label: 'Data' },
        { key: 'valorVenda', label: 'Valor' },
        { key: 'tempoVenda', label: 'Tempo' },
        { key: 'tipoNegociacao', label: 'Tipo' },
        { key: 'arquivoPDF', label: '' }
      ],
      respostaApi: false,
    }
  },
  created() {
    this.hitId = this.$route.params.id;
    this.hitPertenceCompradorInfo();
    this.hitHistoricoCompradorInfo();
  },
  methods: {
    hitHistoricoCompradorInfo() {
      this.carregamentoApi = true
      useJwt.hitHistoricoCompradorInfo({
        hitId: this.hitId,
      })
      .then(response => {
          this.hitHistorico = response.data;
      })
      .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    hitPertenceCompradorInfo() {
      this.carregamentoApi = true
      useJwt.hitPertenceCompradorInfo({
        hitId: this.hitId,
      })
      .then(response => {
        this.hitInfo = response.data;
        this.novaPropostaExclusividade = this.hitInfo.valorExclusividade
        this.pdfSrc = this.hitInfo.arquivoPDF
      })
      .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    tempoVendaEnum(tempoVenda) {
      if (tempoVenda == 0) return '12 meses'
      if (tempoVenda == 1) return '18 meses'
      if (tempoVenda == 2) return '24 meses'
    },

    tipoNegociacaoEnum(tipoNegociacao) {
      if (tipoNegociacao == 0) return 'Liberação'
      if (tipoNegociacao == 1) return 'Exclusividade'
    },

    formatarMoeda(val) {
      return val.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    abrirPDFModal(pdfUrl) {
      this.pdfSrc = pdfUrl
      this.$refs.pdfModal.show()
    },
    togglePDF() {
      if (this.pdfSrc) {
        this.$refs.pdfModal.show()
      } else {
        this.$swal({
          title: 'Nenhum PDF encontrado',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    },
    async downloadHit() {
      this.carregandoDownload = true;
    
      try {
        const response = await fetch(this.hitInfo.arquivoMP3);

        if (!response.ok) {
          throw new Error('Não foi possível baixar o arquivo.');
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        
        const extensao = this.hitInfo.arquivoMP3.split('.').pop()
        const nomeHit = `${this.hitInfo.nomeComposicao}.${extensao}`
      
        link.download = nomeHit;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        this.$swal({
          title: 'Erro ao baixar o arquivo',
          text: error.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      } finally {
        this.carregandoDownload = false;
      }
    },
    
    visualizarHistorico() {
      this.$refs.modalHistorico.show()
    },

    solicitarExclusividade() {
      this.$refs.modalExclusividade.show()
    },

    negociarExclusividadeHit() {
      this.$refs.negociacaoForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          useJwt.negociacaoPropostaUpgradeExclusividade({
            propostaAnteriorId: this.hitInfo.propostaId,
            hitId: router.currentRoute.params.id,
            valor: this.novaPropostaExclusividade,
            tempoVenda: this.tempoVendaSelecionada.id,
          })
            .then(response => {
              this.$swal({
                title: 'Proposta de Exclusividade Enviada!',
                text: 'A sua proposta de exclusividade foi enviada, acompanhe o histórico dessa negociação.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,

              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.go()
                } else {
                  this.$router.go()
                }
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.push({ name: 'negociacoes' })
                }
              })
            })
            .finally(() => {
              this.respostaApi = false
            })
        }
      })
    },
  }
}
</script>

<style lang="scss">
.custom-button {
  height: 30px;
  width: 40px;
  padding: 5px;
  margin-left: -10px;
}

.custom-button .feather-icon {
  width: 14px;    
  height: 14px;   
}

.custom-buttons-row {
  margin: 0 3px 0 3px;
}

.custom-audio-player {
  width: 100% !important;
}

.custom-audio-player svg.audio__play-icon {
  margin-left: -47px;
  margin-right: -5px;
  width: 30px;
  height: 30px;
}

.audio-player {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio-player .audio__progress-wrap {
  margin-top: 0px;
  margin-left: -20px;
}

.audio__btn-wrap {
  flex: 0 0 auto; /* para impedir que o elemento se expanda */
  margin-right: 10px; /* espaço entre o ícone e a barra de progresso */
}

.audio__progress-wrap {
  flex: 1 1 auto; /* para que a barra de progresso ocupe o restante do espaço disponível */
}

.audio-player .audio__time-wrap {
  display: none;
}

.audio-player .audio__play-start {
  color: #727272 !important;
}

.audio-player .audio__play-pause {
  color: #727272 !important;
}

.audio-player .audio__progress-wrap .audio__progress {
  background-color: #ff9f43 !important;
}

.audio-player .audio__progress-wrap .audio__progress-point {
  background-color: #ff9f43 !important;
}

.pdf-container {
  width: 100%;
  height: 100%; 
}

.pdf-viewer {
  width: 100%; 
  height: 100vh; 
}

@media (max-width: 767.98px) {
  .custom-button {
    height: 30px;
    width: 40px;
    padding: 5px;
    margin-left: 40px;
  }

  .custom-audio-player {
    width: 140% !important;
  }

  .custom-audio-player svg.audio__play-icon {
    margin-left: -47px;
    margin-right: -5px;
    width: 30px;
    height: 30px;
  }

  .audio__btn-wrap {
    flex: 0 0 auto; /* para impedir que o elemento se expanda */
    margin-right: 10px; /* espaço entre o ícone e a barra de progresso */
  }

  .audio__progress-wrap {
    margin-left: 0px;
  }

  .btn_historico {
    width: 100%;
  }

  .btn_exclusividade {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
